<template>
  <section class="section">
    <div class="container">
      <div class="sub-nav">
        <div class="container">
          <div class="sub-nav-list">
            <span class="nav-title">My Candidate</span>
            <div class="vline"></div>
            <div class="sub-nav-group">
              <a class="sub-nav-item">Opening Job</a>
              <a class="sub-nav-item">Expired Job</a>
            </div>
          </div>
        </div>
      </div>
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-3 filter">
              <div class="field">
                <label class="label">Search job title</label>
                <div class="control">
                  <input type="text" class="input" placeholder="Job title" />
                </div>
              </div>
              <ul>
                <li v-for="(i, index) in jobtitles" :key="index">
                  <p>{{ i.job_title }}</p>
                  <span>200</span>
                </li>
              </ul>
            </div>
            <div class="column">
              <div
                class="cv-card"
                v-for="(i, index) in 10"
                :key="index"
                :class="{ purchased: purchased }"
              >
                <div class="left">
                  <div class="rate">
                    <span class="mark"><i class="fa-solid fa-star"></i></span>
                    <span class="mark"><i class="fa-solid fa-star"></i></span>
                    <span class="mark"><i class="fa-solid fa-star"></i></span>
                    <span><i class="fa-solid fa-star"></i></span>
                    <span><i class="fa-solid fa-star"></i></span>
                    <p>1.2 k Rating</p>
                  </div>
                  <div class="photo hide">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <p><span>Update on :</span>26/08/2022</p>
                  <p><span>Gender :</span> Male</p>
                  <p><span>Status :</span> Married</p>
                  <p><span>Purchased :</span> 100</p>
                </div>
                <div class="right">
                  <!-- Add class="Saved" for active state -->
                  <p class="save">
                    <span>Save</span>
                    <i class="fa-solid fa-bookmark"></i>
                  </p>
                  <!-- UX / UI Designer -->
                  <div
                    class="group margin-bottom-5-desktop margin-bottom-0-mobile"
                  >
                    <h1>UX / UI Designer</h1>
                  </div>

                  <!-- Latest Salary -->
                  <div class="group">
                    <label>Latest Salary :</label>
                    <h3>5,000,000 - 10,000,000</h3>
                  </div>

                  <!-- Previous Job Title -->
                  <div class="group">
                    <label>Previous Job Title :</label>
                    <ul>
                      <li>IT Support</li>
                      <li>Graphic Designer</li>
                    </ul>
                  </div>

                  <!-- Experiences -->
                  <div class="group">
                    <label>Experiences :</label>
                    <ul>
                      <li>10 Yrs.</li>
                    </ul>
                  </div>
                  <!-- Education -->
                  <div class="group">
                    <label>Education :</label>
                    <ul>
                      <li>
                        <span>Master Degree</span>
                        <span>-</span>
                        <span>Computer Programming</span>
                      </li>
                      <li>
                        <span>Master Degree</span>
                        <span>-</span>
                        <span>Banking</span>
                      </li>
                      <li>
                        <span>Master Degree</span>
                        <span>-</span>
                        <span>Computer Programming</span>
                      </li>
                    </ul>
                  </div>
                  <!-- Language -->
                  <div class="group">
                    <label>Language :</label>
                    <ul>
                      <li>
                        <span>English</span>
                        <span>-</span>
                        <span>Advance</span>
                      </li>
                      <li>
                        <span>Chinese</span>
                        <span>-</span>
                        <span>Advance</span>
                      </li>
                    </ul>
                  </div>
                  <!-- Previous Employer -->
                  <div class="group" v-if="showMoreDetail">
                    <label>Previous Employer :</label>
                    <ul>
                      <li>KPMG</li>
                      <li>PWC</li>
                    </ul>
                  </div>
                  <!-- Previous Working Industry -->
                  <div class="group" v-if="showMoreDetail">
                    <label>Previous Working Industry :</label>
                    <ul>
                      <li>Audit, Tax and Advisory services</li>
                    </ul>
                  </div>
                  <!-- More btn -->
                  <div class="group">
                    <div
                      class="more-btn"
                      @click="showMoreDetail = !showMoreDetail"
                    >
                      <a v-if="!showMoreDetail">More detail</a>
                      <a v-if="showMoreDetail">Less detail</a>
                    </div>
                  </div>

                  <div class="widget" :class="{ purchased: purchased }">
                    <div
                      class="price-tag"
                      v-if="!purchased"
                      @click="confirmModal = !purchased"
                    >
                      <span>Point required:</span>
                      <p>1</p>
                    </div>
                    <div
                      class="price-tag"
                      v-if="purchased"
                      @click="confirmModal = !purchased"
                    >
                      <span>Download CV</span>
                    </div>
                    <label v-if="!purchased">Spend point to download CV</label>
                  </div>
                </div>
                <!-- Right -->
              </div>
              <!-- CV - Card -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="modal is-active" v-if="confirmModal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <span class="close"><i class="fa-regular fa-xmark"></i></span>
          <h3>Confirm your CV purchasing?</h3>
          <hr />
          <h1>01</h1>
          <p>
            Your CV Point will <br />
            be deducted from you account
          </p>
          <button class="button primary is-small" @click="confirm()">
            Confirm
          </button>
        </div>
      </div>
      <button class="modal-close is-large"></button>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    jobtitles: [
      {
        job_title: "Front Office Manager (ຜູ້ຈັດການພະແນກຕ້ອນຮັບ)",
      },
      {
        job_title: "Short-term (10 days) Media Development Consultant",
      },
      {
        job_title: "Analyst, Operations Quality (Based Laos)",
      },
      {
        job_title: "Unilever Future Leaders Program (UFLP) - Finance ",
      },
    ],
    educationLevel: [
      {
        level: "High - School",
      },
      {
        level: "Vocational",
      },
      {
        level: "Higher Diploma",
      },
      {
        level: "Bachelor - Degree",
      },
      {
        level: "Master - Degree",
      },
      {
        level: "Doctoeral - Degree",
      },
    ],
    language: [
      {
        name: "English",
      },
      {
        name: "Chinese",
      },
      {
        name: "Japanese",
      },
      {
        name: "Vietnamese",
      },
    ],
    salaryRange: [
      {
        salary: "1,300,000 - 5,000,000 LAK",
      },
      {
        salary: "5,000,001 - 10,000,000 LAK",
      },
      {
        salary: "10,000,001 - 15,000,000 LAK",
      },
      {
        salary: "15,000,001 - 20,000,000 LAK",
      },
      {
        salary: "20,000,001 - 25,000,000 LAK",
      },
      {
        salary: "More than 25,000,001 LAK",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f7f7f7;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
  h3 {
    font-weight: 700;
  }
  .page-header-end {
    display: flex;
    align-items: center;
    .field {
      display: flex;
      align-items: center;
      margin: 0;
      .label {
        font-weight: normal;
        margin: 0 10px 0 0;
      }
    }
  }
}

.cv-card {
  border: 1px solid var(--border-color);
  padding: 20px;
  background-color: #fff;
  display: flex;
  margin-bottom: 16px;
  &.purchased {
    .right {
      .widget {
        label {
          color: var(--dark-grey-color);
          font-size: var(--sm-font);
          pointer-events: none;
        }
        .price-tag {
          background-color: var(--primary-color);
          color: #fff;
        }
      }
    }
  }
  .left {
    max-width: 150px;
    min-width: 150px;
    .rate {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 5px;
      span {
        font-size: var(--xxsm-font);
        i {
          color: var(--dark-grey-color);
        }
        &.mark {
          i {
            color: var(--info-color);
          }
        }
      }
      p {
        font-size: var(--xxsm-font);
        margin: 0 0 0 5px !important;
      }
    }
    .photo {
      min-width: 130px;
      max-width: 130px;
      min-height: 130px;
      max-height: 130px;
      background-color: var(--light-grey-color);
      border-radius: 10px;
      margin-bottom: 10px;
      &.hide {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: var(--xxlg-font);
          color: var(--grey-color);
        }
      }
    }
    p {
      margin-top: 5px;
      display: flex;
      font-size: var(--xsm-font);
      line-height: 1.2;
      span {
        color: var(--dark-grey-color);
        font-size: var(--xsm-font);
        margin-right: 5px;
      }
    }
  }
  .right {
    margin-left: 10px;
    position: relative;
    .save {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      gap: 5px;
      align-items: center;
      span {
        font-size: var(--xsm-font);
        color: var(--dark-grey-color);
      }
      i {
        color: var(--dark-grey-color);
      }
      &.saved i {
        color: var(--primary-color);
      }
    } // bookmark
    .group {
      display: flex;
      h1 {
        font-weight: 700;
        margin: 0;
        font-size: var(--xxsm-font);
      }
      h3 {
        font-weight: 700;
        margin: 0;
        span {
          font-weight: normal;
          margin-right: 5px;
        }
      }
      label {
        margin-bottom: 5px;
        font-size: var(--sm-font);
        color: var(--dark-grey-color);
        min-width: 160px;
        max-width: 160px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: var(--sm-font);
          display: flex;
          span:not(:last-child) {
            margin-right: 5px;
          }
          &::after {
            content: ",";
            margin-right: 8px;
          }
        }
      }
      .more-btn a {
        color: var(--alert-color);
      }
    }
  }
  .widget {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border-color);
    padding-top: 10px;
    margin-top: 10px;
    font-size: var(--sm-font);
    label {
      color: var(--dark-grey-color);
      font-size: var(--sm-font);
      pointer-events: none;
    }
    .price-tag {
      cursor: pointer;
      margin-right: 10px;
      padding: 3px 10px;
      background-color: var(--info-color);
      color: var(--text-color);
      border-radius: 5px;
      display: flex;
      font-size: var(--sm-font);
      p {
        font-weight: 700;
      }
      span {
        margin-right: 5px;
        font-size: var(--sm-font);
      }
    }
  }
}

.filter {
  ul {
    display: flex;
    gap: 5px;
    flex-direction: column;
    li {
      border-radius: 5px;
      border: 1px solid var(--border-color);
      background-color: #fff;
      box-shadow: none;
      display: flex;
      cursor: pointer;
      align-items: center;
      transition: all ease-in-out 0.2s;
      padding: 10px 15px;
      font-size: var(--sm-font);
      p {
        flex-grow: 1;
        font-size: var(--sm-font);
      }
      span {
        width: 100%;
        background-color: var(--info-color);
        color: #fff;
        max-width: 25px;
        margin-left: auto;
        font-size: var(--xsm-font);
        text-align: center;
        border-radius: 3px;
        height: 100%;
      }
    }
  }
}
</style>
